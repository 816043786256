import dynamic from 'next/dynamic';
import { useEffect } from 'react';

import { FeatureNameEnum } from 'config/constants/featureDefinitions';
import { logVariant, useFeatureFlag } from 'hooks/useFeatureFlags';

import FreeWordLimitModal from '../components/modals/FreeWordLimit';
import TempWordLimitModal from '../components/modals/TempWordLimit';
import { hideUpsellModal } from '../stores/actions/hideUpsellModal';
import { UpsellModalType } from '../stores/types';
import { useUpsellStore } from '../stores/upsellStores';

const ContextualModal = dynamic(() => import('../components/modals/Contextual'), { ssr: false });
const GlobalModal = dynamic(() => import('../components/modals/Global'), { ssr: false });

export const UpsellModalContainer = () => {
  const { variant: showContextualUpsellVariant, isLoading: isShowContextualUpsellVariantLoading } = useFeatureFlag(FeatureNameEnum.SHOW_CONTEXTUAL_UPSELL);

  useEffect(() => {
    if (!isShowContextualUpsellVariantLoading) {
      const mappedVariant = showContextualUpsellVariant ? 'enabled' : 'control';
      logVariant(FeatureNameEnum.SHOW_CONTEXTUAL_UPSELL, mappedVariant);
    }
  }, [showContextualUpsellVariant, isShowContextualUpsellVariantLoading]);

  const activeModal = useUpsellStore(state => state.activeModal);

  const modalMap = {
    [UpsellModalType.ContextualAskAI]: ContextualModal,
    [UpsellModalType.ContextualCloneVoice]: ContextualModal,
    [UpsellModalType.ContextualDownloadMP3]: ContextualModal,
    [UpsellModalType.ContextualImageOnlyPDFs]: ContextualModal,
    [UpsellModalType.ContextualIncreaseSpeed]: ContextualModal,
    [UpsellModalType.ContextualPremiumVoices]: ContextualModal,
    [UpsellModalType.ContextualSkipHeadersFooters]: ContextualModal,
    [UpsellModalType.ContextualSupport]: ContextualModal,
    [UpsellModalType.ContextualUnlimitedFiles]: ContextualModal,
    [UpsellModalType.Global]: GlobalModal,

    [UpsellModalType.TempWordLimit]: TempWordLimitModal,
    [UpsellModalType.FreeWordLimit]: FreeWordLimitModal
  };

  const renderModals = () => {
    if (showContextualUpsellVariant === true && activeModal) {
      const ModalComponent = modalMap[activeModal];

      if (ModalComponent) {
        return <ModalComponent onCancel={hideUpsellModal} open={true} type={activeModal} />;
      }
    }

    return null;
  };

  return <>{renderModals()}</>;
};
