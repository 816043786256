import Modal from 'components/elements/Modal';
import { X } from 'lucide-react';
import { useCallback } from 'react';

import { onUpgradeButtonClick } from 'modules/subscription/utils/upgrade';
import { UpgradeSource } from 'modules/subscription/utils/UpgradeSource';
import { FindSVG } from 'modules/upsells/assets/FindSVG';
import { usePlayAudioVisualCtaOnOpen } from 'modules/upsells/hooks/usePlayAudioVisualCtaOnOpen';
import { UpsellModalType } from 'modules/upsells/stores/types';

import { UpgradeToPremiumButton } from '../../UpgradeToPremiumButton';

const FreeWordLimitModal = ({ onCancel, open }: { onCancel: () => void; open: boolean; type: UpsellModalType }) => {
  usePlayAudioVisualCtaOnOpen(open);
  const onUpgradeClick = useCallback(() => {
    onUpgradeButtonClick(UpgradeSource.visualCta, UpsellModalType.FreeWordLimit);
  }, []);

  return (
    <Modal classNames="md:max-w-[540px]" open={open} onClose={onCancel}>
      <div className="flex flex-col gap-spl-9 items-center relative justify-center px-[58px] py-[40px] bg-bg-primary">
        <div className="absolute top-2 right-2 [&_svg]:stroke-[var(--colors-text-primary)]">
          <button onClick={onCancel}>
            <X size={24} />
          </button>
        </div>
        <FindSVG />
        <div className="text-center text-text-primary">
          <h3 className="text-heading-2">Continue using Premium Voices?</h3>
          <p className="text-body-5">
            You have reached your premium words limit on the free plan. To continue your premium experience, please Upgrade to Speechify Premium.
          </p>
        </div>
        <UpgradeToPremiumButton onClick={onUpgradeClick} />
      </div>
    </Modal>
  );
};

export default FreeWordLimitModal;
