export const FindSVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={213} height={213} viewBox="0 0 213 213" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.6045 73.5186C3.15528 50.8795 3.93067 39.56 16.0797 27.4408C28.2287 15.3216 39.4638 14.5797 61.9338 13.096C73.1676 12.3542 86.1565 11.8652 100.879 11.8652C115.601 11.8652 128.59 12.3542 139.824 13.096C162.294 14.5797 173.529 15.3216 185.678 27.4408C197.827 39.56 198.603 50.8795 200.153 73.5186C200.93 84.855 201.446 97.8691 201.446 112.433C201.446 126.156 200.988 138.503 200.285 149.367C198.748 173.135 197.979 185.019 185.836 197.193C173.692 209.367 161.725 210.171 137.792 211.778C126.82 212.515 114.445 213 100.879 213C87.3128 213 74.9378 212.515 63.9663 211.778C40.0327 210.171 28.0659 209.367 15.9222 197.193C3.77843 185.019 3.00984 173.135 1.47267 149.367C0.770049 138.503 0.311462 126.156 0.311462 112.433C0.311462 97.8691 0.827956 84.855 1.6045 73.5186Z"
      className="fill-[#FFF1E1] dark:fill-[#0E0E0E]"
    />
    <path
      d="M174.188 1.94309C174.92 1.19835 175.918 0.774833 176.962 0.765721L195.414 0.604696C196.458 0.595583 197.463 1.00162 198.207 1.73348L211.369 14.6669C212.113 15.3988 212.537 16.3965 212.546 17.4407L212.707 35.8924C212.716 36.9365 212.31 37.9415 211.578 38.6862L198.645 51.8474C197.913 52.5921 196.915 53.0156 195.871 53.0248L177.419 53.1858C176.375 53.1949 175.37 52.7889 174.625 52.057L161.464 39.1235C160.72 38.3917 160.296 37.3939 160.287 36.3498L160.126 17.8981C160.117 16.854 160.523 15.849 161.255 15.1043L174.188 1.94309Z"
      className="fill-[#FF2C6B] dark:fill-[#FF463C]"
    />
    <path
      d="M49.3067 92.873C44.609 103.965 33.6195 131.195 44.1525 159.209"
      className="stroke-[#FFD24A] dark:stroke-[#E6B041]"
      strokeWidth={4.72441}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M152.945 96.5979C168.092 91.9609 170.855 74.538 164.919 67.0474C155.484 55.1411 141.889 55.3305 131.676 59.0098C130.199 57.8425 124.311 54.5988 122.184 54.457"
      className="stroke-[#FFBC41] dark:stroke-[#E6B041]"
      strokeWidth={4.72441}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx={101.383} cy={108.031} r={56.2812} fill="url(#paint0_linear)" />
    <mask
      id="mask0"
      style={{
        maskType: 'alpha'
      }}
      maskUnits="userSpaceOnUse"
      x={45}
      y={51}
      width={113}
      height={114}
    >
      <circle cx={101.384} cy={108.031} r={56.2812} fill="url(#paint1_linear)" />
    </mask>
    <g mask="url(#mask0)">
      <g className="mix-blend-hard-light" filter="url(#filter0_f)">
        <path
          d="M172.335 44.1709C201.448 22.5438 247.962 164.442 172.335 220.466C133.764 245.026 21.1912 247.909 21.1911 170.784C21.1911 147.59 72.292 181.194 129.844 137.61C178.946 100.424 143.222 65.798 172.335 44.1709Z"
          className="fill-[#F040FF] dark:fill-[#FF3B3E]"
        />
      </g>
      <g filter="url(#filter1_f)">
        <path
          d="M164.182 124.758C170.085 134.029 157.01 152.918 134.978 166.946C112.946 180.975 90.3002 184.831 84.3969 175.56C78.4937 166.289 108.669 170.755 130.701 156.727C152.733 142.698 158.279 115.487 164.182 124.758Z"
          className="fill-[#FFC347] dark:fill-[#9A6504]"
        />
      </g>
      <g className="mix-blend-overlay" filter="url(#filter2_f)">
        <path
          d="M148.307 32.037C158.75 48.4367 109.909 36.9218 71.3377 61.4816C32.7664 86.0413 19.0707 137.375 8.62844 120.976C-1.81385 104.576 20.9892 71.3717 59.5605 46.812C98.1318 22.2522 137.865 15.6373 148.307 32.037Z"
          fill="#DCFF78"
        />
      </g>
    </g>
    <circle cx={6.03013} cy={6.03013} r={6.03013} transform="matrix(-1 0 0 1 94.6836 63.1387)" fill="#1E1E1E" />
    <circle cx={6.03013} cy={6.03013} r={6.03013} transform="matrix(-1 0 0 1 120.381 63.1387)" fill="#1E1E1E" />
    <path
      d="M107.28 92.1549C107.405 94.5956 106.948 97.031 105.948 99.2606C105.403 100.483 104.418 101.456 103.19 101.986C101.961 102.517 100.578 102.567 99.3148 102.126C98.5737 101.85 97.8978 101.424 97.3295 100.874C96.7612 100.324 96.3129 99.6626 96.0127 98.9311C94.5801 95.9011 94.5156 92.685 94.8523 89.4617C95.0012 88.0423 95.3335 86.6483 95.8408 85.3143C96.1816 84.3023 96.8332 83.4237 97.7026 82.8038C98.5721 82.1839 99.6151 81.8542 100.683 81.8618C101.782 81.7656 102.884 82.0183 103.832 82.5841C104.78 83.1499 105.525 83.9999 105.962 85.0135C106.961 87.2559 107.413 89.7037 107.28 92.1549Z"
      fill="#1E1E1E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M186.416 31.7479C185.047 31.7479 183.921 30.669 183.863 29.301L183.263 15.2699C183.186 13.4753 184.62 11.9785 186.416 11.9785C188.213 11.9785 189.647 13.4753 189.57 15.2699L188.97 29.301C188.911 30.669 187.785 31.7479 186.416 31.7479Z"
      fill="white"
    />
    <circle cx={186.417} cy={38.6633} r={3.14961} fill="white" />
    <defs>
      <filter id="filter0_f" x={-19.3788} y={1.37746} width={273.34} height={275.067} filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation={20.2849} result="effect1_foregroundBlur" />
      </filter>
      <filter id="filter1_f" x={72.0217} y={111.248} width={105.202} height={80.9855} filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation={5.81229} result="effect1_foregroundBlur" />
      </filter>
      <filter id="filter2_f" x={-34.4736} y={-17.5405} width={224.782} height={182.257} filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation={20.2849} result="effect1_foregroundBlur" />
      </filter>
      <linearGradient id="paint0_linear" x1={101.383} y1={51.75} x2={150.63} y2={160.962} gradientUnits="userSpaceOnUse">
        <stop stopColor="#FFCE51" />
        <stop offset={1} stopColor="#FFAE35" />
      </linearGradient>
      <linearGradient id="paint1_linear" x1={101.384} y1={51.75} x2={150.63} y2={160.962} gradientUnits="userSpaceOnUse">
        <stop stopColor="#FFCE51" />
        <stop offset={1} stopColor="#FFAE35" />
      </linearGradient>
    </defs>
  </svg>
);
