import DiamondIcon from 'components/newListeningExperience/icons/DiamondIcon';

import { cn } from 'utils/cn';

const yellowGradient =
  'linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.40) 100%), radial-gradient(113.68% 113.68% at 84.82% -14.29%, rgba(255, 230, 0, 0.80) 0%, rgba(255, 149, 0, 0.00) 100%), radial-gradient(97.58% 151.79% at -6.25% 114.29%, rgba(255, 26, 150, 0.40) 0%, rgba(250, 0, 255, 0.03) 84.18%, rgba(250, 0, 255, 0.00) 100%), linear-gradient(283deg, #FFA82F 0.25%, #FF795B 100%)';

interface UpgradeToPremiumButtonProps {
  onClick: () => void;
  text?: string;
  variation?: 'default' | 'popup';
}

export const UpgradeToPremiumButton = ({ onClick, text = 'Upgrade to Premium', variation = 'default' }: UpgradeToPremiumButtonProps) => {
  return (
    <button
      onClick={onClick}
      style={{ background: yellowGradient, backgroundBlendMode: 'overlay' }}
      className={cn(
        'py-spl-6 max-w-[279px] flex justify-center items-center gap-spl-4 rounded-250 w-full text-spl-glass-0 whitespace-nowrap',
        variation === 'popup' ? ' text-sm pl-4 pr-6' : 'pl-12 pr-14'
      )}
    >
      <DiamondIcon />
      {text}
    </button>
  );
};
