import * as React from 'react';

function DiamondIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={19} height={17} viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.187 2.12A.25.25 0 015.401 2H13.6a.25.25 0 01.213.12l2.37 3.874a.25.25 0 01-.02.288l-6.47 7.903a.25.25 0 01-.386 0l-6.47-7.903a.25.25 0 01-.02-.288l2.37-3.874zM5.401 0a2.25 2.25 0 00-1.92 1.076L1.112 4.95a2.25 2.25 0 00.178 2.6l6.47 7.902a2.25 2.25 0 003.482 0l6.47-7.903a2.25 2.25 0 00.179-2.6l-2.371-3.873A2.25 2.25 0 0013.599 0H5.402zM6 5a1 1 0 100 2h7a1 1 0 100-2H6z"
        fill="#fff"
      />
    </svg>
  );
}

export default DiamondIcon;
