import { UpsellModalType } from 'modules/upsells/stores/types';

import { AnalyticsEventKey } from '../analyticsTypes';
import { logAnalyticsEvent } from '../logAnalyticsEvent';

const UPSELL_MODAL_TYPE_TO_CLOSED_MODAL_ANALYTICS_EVENT_KEY: Partial<Record<UpsellModalType, AnalyticsEventKey>> = {
  [UpsellModalType.FreeWordLimit]: AnalyticsEventKey.upsellModalsAudioVisualCtaClosed
};

export const logUpsellModalClosedAnalyticsEvent = (modalType: UpsellModalType | null) => {
  if (!modalType) return;

  const analyticsEvent = UPSELL_MODAL_TYPE_TO_CLOSED_MODAL_ANALYTICS_EVENT_KEY[modalType];

  if (analyticsEvent) {
    logAnalyticsEvent(analyticsEvent, {});
  }
};
