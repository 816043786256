import React, { useEffect } from 'react';

import { playbackStoreActions } from 'modules/listening/stores/playback/playbackStore';
import { subscriptionStoreSelectors } from 'modules/subscription/stores/selectors';

async function _playAudioVisualCta(audioRef: React.MutableRefObject<HTMLAudioElement | null>) {
  playbackStoreActions.pause();
  await new Promise(resolve => setTimeout(resolve, 1000));
  const audio = new Audio('/audio/en-US-Matthew-Audio-Visual-cta.mp3');
  audio.onended = () => {
    playbackStoreActions.resume();
  };
  audioRef.current = audio;
  audio.play();
  return audio;
}

export function usePlayAudioVisualCtaOnOpen(isOpen: boolean) {
  const audioRef = React.useRef<HTMLAudioElement | null>(null);

  useEffect(() => {
    const isPremiumUser = subscriptionStoreSelectors.getIsPremium();
    if (isPremiumUser) return;

    const playAudioVisualCta = async () => {
      // ensure we only have one audio playing at a time
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
      if (isOpen) {
        await _playAudioVisualCta(audioRef);
      }
    };

    playAudioVisualCta();

    return () => {
      if (audioRef.current && audioRef.current.paused === false) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        const audio = audioRef.current;
        audio.pause();
        audio.currentTime = 0;
        playbackStoreActions.resume();
      }
    };
  }, [isOpen]);
}
